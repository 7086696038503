<template>
  <van-nav-bar
    :title="title"
    right-text="收款码"
    @click="onClick"
  />
  <van-cell style="margin:7px 0 0 0;" title="分润设置" is-link :to="{ name:'ProfitSharingSettings', query: { productId: productId, type: 'freepay' } }" />
  <van-grid :column-num="2" style="margin:7px 0;">
    <van-grid-item>
      <p>
        <small>{{ v1 }} 元</small>
      </p>
      <p>
        <small>累计实收</small>
      </p>
    </van-grid-item>
    <van-grid-item>
      <p>
        <small>{{ v2 }} 元</small>
      </p>
      <p>
        <small>昨日实收</small>
      </p>
    </van-grid-item>
  </van-grid>

  <div class="seller">
    <van-cell
      title="开始时间"
      is-link
      :value="timeValue"
      @click="showPopup" />
    <van-popup v-model:show="show" position="bottom">
      <van-datetime-picker
        v-model="startCreateTime"
        type="date"
        title="选择时间"
        :loading="isLoadingShow"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="show = false"
        @confirm="confirmPicker"
      />
    </van-popup>

    <van-cell
      title="结束时间"
      is-link
      :value="timeValueend"
      @click="showPopupend" />
    <van-popup v-model:show="showend" position="bottom">
      <van-datetime-picker
        v-model="endCreateTime"
        type="date"
        title="选择时间"
        :loading="isLoadingShowend"
        :min-date="minDate"
        :max-date="maxDate"
        @cancel="showend = false"
        @confirm="confirmPickerend"
      />
    </van-popup>
    <div style="display: flex;align-items: center;">
      <van-button type="primary" block @click="getDatatime()">查询</van-button>
    </div>
  </div>

  <van-list
    v-model:loading="loading"
    :finished="finished"
    finished-text="没有更多了"
    @load="getData"
  >
    <template v-for="item in list" :key="item">
      <div style="background:#fff;padding:15px;margin:7px 0 0 0;">
        <p style="margin:0 0 15px 0;">
          <small>实收金额 {{ item.payAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>分账金额 {{ item.commissionAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>最终收益 {{ item.profitAmount }}</small>
        </p>
        <p style="margin:0 0 15px 0;" v-if="item.buyer">
          <small>付款手机号 {{ item.buyer.user.phone }}</small>
        </p>
        <p style="margin:0 0 15px 0;" v-if="item.buyer">
          <small>付款人昵称 {{ item.buyer.name || item.buyer.user.name || item.member.name }}</small>
        </p>
        <p style="margin:0 0 15px 0;">
          <small>时间 {{ item.createTime }}</small>
        </p>
        <van-button size="small" :to="{ name: 'OrderCommission', query: { orderId: item.orderId } }">分润记录</van-button>
      </div>
    </template>
  </van-list>
</template>

<script>
import { toRefs, reactive, onMounted, inject } from 'vue'
import dayjs from 'dayjs'
import { Toast, Dialog } from 'vant'

export default {
  setup () {
    const post = inject('post')
    const Cookies = inject('Cookies')
    const useRouter = inject('useRouter')
    const state = reactive({
      timeValue: '请选择时间',
      timeValueend: '请选择时间',
      isLoadingShow: true,
      startCreateTime: '',
      endCreateTime: '',
      show: false,
      showend: false,
      isLoadingShowend: true,
      minDate: new Date(2010, 12, 31),
      maxDate: new Date(),
      page: 1,
      finished: false,
      loading: false,
      list: [],
      title: '',
      storeId: Cookies.get('storeId'),
      v1: 0,
      v2: 0,
      productId: undefined
    })
    const showPopup = () => {
      state.show = true
      state.isLoadingShow = true
      setTimeout(() => {
        state.isLoadingShow = false
      }, 500)
    }
    const showPopupend = () => {
      state.showend = true
      state.isLoadingShowend = true
      setTimeout(() => {
        state.isLoadingShowend = false
      }, 500)
    }
    // 确认选择的时间
    const confirmPicker = (val) => {
      console.log(val)
      const year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      state.timeValue = `${year}-${month}-${day}`
      state.show = false
      state.startCreateTime = state.timeValue + ' 00:00:00'
    }
    const confirmPickerend = (val) => {
      const year = val.getFullYear()
      let month = val.getMonth() + 1
      let day = val.getDate()
      if (month >= 1 && month <= 9) { month = `0${month}` }
      if (day >= 1 && day <= 9) { day = `0${day}` }
      state.timeValueend = `${year}-${month}-${day}`
      state.showend = false
      state.endCreateTime = state.timeValueend + ' 23:59:59'
    }
    const onUpdateValue = (newValue) => {
      Dialog.confirm({
        title: '提醒',
        message: '操作确认？'
      }).then(() => {
        post('/product.setFreePay', {
          enable: newValue,
          storeId: state.storeId
        }).then(res => {
          if (res.code === 0) {
            state.checked = newValue
          } else {
            Toast(res.msg)
          }
        })
      })
    }
    const onClick = () => {
      useRouter.push({
        name: 'FreePayQrCode'
      })
    }
    const getDatatime = () => {
      state.list = []
      state.page = 1
      getData()
    }
    const getData = () => {
      post('/order.list', {
        pageNum: state.page,
        storeId: state.storeId,
        bizScope: 'FREE_PAY',
        minCreateTime: state.startCreateTime,
        maxCreateTime: state.endCreateTime
      }).then(res => {
        state.list = [...state.list, ...res.data.content]
        state.loading = false
        state.finished = res.data.last
        state.page++
        state.title = '总共 ' + res.data.totalElements + ' 条记录'
      }).catch(err => {
        alert(JSON.stringify(err))
      })
    }
    const sum = () => {
      post('/order.sumOrder', {
        storeId: state.storeId,
        bizScope: 'FREE_PAY',
        status: 'COMPLETED'
      }).then(res => {
        state.v1 = res.data.payAmount
      })
      post('/order.sumOrder', {
        storeId: state.storeId,
        bizScope: 'FREE_PAY',
        status: 'COMPLETED',
        minCompleteTime: dayjs().subtract(1, 'day').set('hour', 0).set('minute', 0).set('second', 0).format('YYYY-MM-DD HH:mm:ss'),
        maxCompleteTime: dayjs().subtract(1, 'day').set('hour', 23).set('minute', 59).set('second', 59).format('YYYY-MM-DD HH:mm:ss')
      }).then(res => {
        state.v2 = res.data.payAmount
      })
    }
    const init = () => {
      sum()
      post('/product.openFreePay', {
        storeId: state.storeId
      }).then(res => {
        if (res.code === 0) {
          state.productId = res.data
        }
      })
    }
    onMounted(() => {
      init()
    })
    return {
      ...toRefs(state),
      getData,
      onClick,
      onUpdateValue,
      confirmPicker,
      confirmPickerend,
      showPopup,
      showPopupend,
      getDatatime
    }
  }
}
</script>

<style scoped>
body {
  background:#f9f9f9;
}
</style>
